import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { AnalysisApiService } from '../services/analysis-api-service';
import { PathResolveService } from 'src/app/services/path-resolve.service';
import { Observable, forkJoin, of } from 'rxjs';
import { ComparisonDataService, ComparisonVersionMappingEntry } from 'src/app/navigation/services/comparison-data-service';
import { mergeMap } from 'rxjs/operators';
import { HistoryWrapperLifeCycle } from '../entities/evaluation-entities/history-wrapper-life-cycle';
import { RecyclabilityResultDto } from '../entities/evaluation-entities/recyclability-result-dto';
import { HistoryWrapperRecyclability } from '../entities/evaluation-entities/history-wrapper-recyclability';
import { LifeCycleResultDto } from '../entities/evaluation-entities/life-cycle-result-dto';
import { PackagingSystemLifeCycleCradleToGateResultDto } from '../entities/evaluation-entities/packaging-part-results/packaging-system-life-cycle-cradle-to-gate-result-dto';
import { PackagingUnitLifeCycleCradleToGateResultDto } from '../entities/evaluation-entities/packaging-part-results/packaging-unit-life-cycle-cradle-to-gate-result-dto';
import { RecyclingStreamTranslationsDto } from '../entities/evaluation-entities/recycling-stream-dto';
import { ExpenseResultDto, PackagingUnitExpenseResultDto } from '../entities/evaluation-entities/expense-result-dto';
import { EvaluationGenerationDto } from '../entities/evaluation-entities/evaluation-generation-dto';
import { PackagingPart } from 'src/app/model/packaging-part-enum';

// --- UNITS ---

export const recyclabilityEvaluationHistoryResolverUnit: ResolveFn<RecyclabilityResultDto[][]> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getPackagingUnitIdFromRoute(route);
  const versionId = PathResolveService.getPackagingVersionIdFromRoute(route);
  return (id != null && versionId != null) ?
    inject(AnalysisApiService).getPackagingUnitRecyclabilityHistoryResults(id, versionId) :
    of([] as RecyclabilityResultDto[][]);
};

export const recyclabilityComparisonResolverUnit: ResolveFn<Observable<HistoryWrapperRecyclability[]>> = () => {
  const versionMapping: ComparisonVersionMappingEntry[] = inject(ComparisonDataService).getPackagingVersionMapping();
  if (!versionMapping) { return of([] as HistoryWrapperRecyclability[]); }

  const result: Observable<HistoryWrapperRecyclability>[] = [];
  for (const versionMappingEntry of versionMapping) {
    if (versionMappingEntry.id != null && versionMappingEntry.version != null) {
      result.push(inject(AnalysisApiService).getPackagingUnitRecyclabilityHistoryResults(
        versionMappingEntry.id, versionMappingEntry.version).pipe(
          mergeMap(evaluationResult => {
            const wrapper = new HistoryWrapperRecyclability();
            wrapper.id = versionMappingEntry.id ?? -1;
            wrapper.version = versionMappingEntry.version ?? -1;
            if (evaluationResult) {
              wrapper.analysisId = evaluationResult.length > 0 ? evaluationResult[0][0]?.id : '';
              wrapper.recyclabilityDtos = evaluationResult;
            }
            return of(wrapper);
          })));
    }
  }
  return forkJoin(result);
};

export const licenseFeeEvaluationHistoryResolverUnit: ResolveFn<PackagingUnitExpenseResultDto[][]> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getPackagingUnitIdFromRoute(route);
  const versionId = PathResolveService.getPackagingVersionIdFromRoute(route);
  return (id != null && versionId != null) ?
    inject(AnalysisApiService).getPackagingUnitLicenseFeeHistoryResults(id, versionId) :
    of([] as PackagingUnitExpenseResultDto[][]);
};

export const plasticTaxEvaluationHistoryResolverUnit: ResolveFn<PackagingUnitExpenseResultDto[][]> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getPackagingUnitIdFromRoute(route);
  const versionId = PathResolveService.getPackagingVersionIdFromRoute(route);
  return (id != null && versionId != null) ?
    inject(AnalysisApiService).getPackagingUnitPlasticTaxHistoryResults(id, versionId) :
    of([] as PackagingUnitExpenseResultDto[][]);
};

export const lifeCycleEvaluationHistoryResolverUnit: ResolveFn<LifeCycleResultDto[][]> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getPackagingUnitIdFromRoute(route);
  const versionId = PathResolveService.getPackagingVersionIdFromRoute(route);
  return (id != null && versionId != null) ?
    inject(AnalysisApiService).getPackagingUnitLifeCycleHistoryResults(id, versionId) :
    of([] as LifeCycleResultDto[][]);
};

export const lifeCycleCradleToGateEvaluationHistoryResolverUnit: ResolveFn<PackagingUnitLifeCycleCradleToGateResultDto[][]> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getPackagingUnitIdFromRoute(route);
  const versionId = PathResolveService.getPackagingVersionIdFromRoute(route);
  const version:number = versionId??-1;
  return (id != null && versionId != null) ?
    inject(AnalysisApiService).getPackagingUnitLifeCycleCradleToGateHistoryResults(id, version) :
    of([] as PackagingUnitLifeCycleCradleToGateResultDto[][]);
};

export const lcaComparisonResolverUnit: ResolveFn<Observable<HistoryWrapperLifeCycle[]>> = () => {
  const versionMapping: ComparisonVersionMappingEntry[] = inject(ComparisonDataService).getPackagingVersionMapping();
  if (!versionMapping) { return of([] as HistoryWrapperLifeCycle[]); }

  const result: Observable<HistoryWrapperLifeCycle>[] = [];
  for (const versionMappingEntry of versionMapping) {
    if (versionMappingEntry.id != null && versionMappingEntry.version != null) {
      result.push(inject(AnalysisApiService).getPackagingUnitLifeCycleHistoryResults(
        versionMappingEntry.id, versionMappingEntry.version).pipe(
          mergeMap(evaluationResult => {
            const wrapper = new HistoryWrapperLifeCycle();
            wrapper.id = versionMappingEntry.id ?? -1;
            wrapper.version = versionMappingEntry.version ?? -1;
            if (evaluationResult) {
              wrapper.lifeCycleDtos = evaluationResult;
            }
            return of(wrapper);
          })));
    }
  }
  return forkJoin(result);
};

export const recyclingStreamsResolver: ResolveFn<RecyclingStreamTranslationsDto[]> = () => {
  return inject(AnalysisApiService).getRecyclingStreams();
};

// --- SYSTEMS ---

export const recyclabilityEvaluationHistoryResolverSystem: ResolveFn<RecyclabilityResultDto[][]> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getPackagingUnitIdFromRoute(route);
  const versionId = PathResolveService.getPackagingVersionIdFromRoute(route);
  return (id != null && versionId != null) ?
    inject(AnalysisApiService).getPackagingSystemRecyclabilityHistoryResults(id, versionId) :
    of([] as RecyclabilityResultDto[][]);
};

export const recyclabilityComparisonResolverSystem: ResolveFn<Observable<HistoryWrapperRecyclability[]>> = () => {
  const versionMapping: ComparisonVersionMappingEntry[] = inject(ComparisonDataService).getPackagingVersionMapping();
  if (!versionMapping) { return of([] as HistoryWrapperRecyclability[]); }

  const result: Observable<HistoryWrapperRecyclability>[] = [];
  for (const versionMappingEntry of versionMapping) {
    if (versionMappingEntry.id != null && versionMappingEntry.version != null) {
      result.push(inject(AnalysisApiService).getPackagingSystemRecyclabilityHistoryResults(
        versionMappingEntry.id, versionMappingEntry.version).pipe(
          mergeMap(evaluationResult => {
            const wrapper = new HistoryWrapperRecyclability();
            wrapper.id = versionMappingEntry.id ?? -1;
            wrapper.version = versionMappingEntry.version ?? -1;
            if (evaluationResult) {
              wrapper.analysisId = evaluationResult.length > 0 ? evaluationResult[0][0]?.id : '';
              wrapper.recyclabilityDtos = evaluationResult;
            }
            return of(wrapper);
          })));
    }
  }
  return forkJoin(result);
};

export const lifeCycleEvaluationHistoryResolverSystem: ResolveFn<LifeCycleResultDto[][]> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getPackagingUnitIdFromRoute(route);
  const versionId = PathResolveService.getPackagingVersionIdFromRoute(route);
  return (id != null && versionId != null) ?
    inject(AnalysisApiService).getPackagingSystemLifeCycleHistoryResults(id, versionId) :
    of([] as LifeCycleResultDto[][]);
};

export const lifeCycleCradleToGateEvaluationHistoryResolverSystem: ResolveFn<PackagingSystemLifeCycleCradleToGateResultDto[][]> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getPackagingUnitIdFromRoute(route);
  const versionId = PathResolveService.getPackagingVersionIdFromRoute(route);
  const version:number = versionId??-1;
  return (id != null && versionId != null) ?
    inject(AnalysisApiService).getPackagingSystemLifeCycleCradleToGateHistoryResults(id, version) :
    of([] as PackagingSystemLifeCycleCradleToGateResultDto[][]);
};

export const lcaComparisonResolverSystem: ResolveFn<Observable<HistoryWrapperLifeCycle[]>> = () => {
  const versionMapping: ComparisonVersionMappingEntry[] = inject(ComparisonDataService).getPackagingVersionMapping();
  if (!versionMapping) { return of([] as HistoryWrapperLifeCycle[]); }

  const result: Observable<HistoryWrapperLifeCycle>[] = [];
  for (const versionMappingEntry of versionMapping) {
    if (versionMappingEntry.id != null && versionMappingEntry.version != null) {
      result.push(inject(AnalysisApiService).getPackagingSystemLifeCycleHistoryResults(
        versionMappingEntry.id, versionMappingEntry.version).pipe(
          mergeMap(evaluationResult => {
            const wrapper = new HistoryWrapperLifeCycle();
            wrapper.id = versionMappingEntry.id ?? -1;
            wrapper.version = versionMappingEntry.version ?? -1;
            if (evaluationResult) {
              wrapper.lifeCycleDtos = evaluationResult;
            }
            return of(wrapper);
          })));
    }
  }
  return forkJoin(result);
};

// TODO temporary. Remove when expense costs are persisted and can be retrieved using history path

export const plasticTaxResolverSystems: ResolveFn<ExpenseResultDto[]> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getPackagingUnitIdFromRoute(route);
  const version = PathResolveService.getPackagingVersionIdFromRoute(route);
  if (id != null && version != null) {
    const evaluationGenerationDto: EvaluationGenerationDto = { id, version };
    return inject(AnalysisApiService).getPlasticTaxCalculationResult(evaluationGenerationDto, PackagingPart.System);
  }
  return of([] as ExpenseResultDto[]);
};

export const licenseFeeResolverSystems: ResolveFn<ExpenseResultDto[]> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getPackagingUnitIdFromRoute(route);
  const version = PathResolveService.getPackagingVersionIdFromRoute(route);
  if (id != null && version != null) {
    const evaluationGenerationDto: EvaluationGenerationDto = { id, version };
    return inject(AnalysisApiService).getLicenseFeeCalculationResult(evaluationGenerationDto, PackagingPart.System);
  }
  return of([] as ExpenseResultDto[]);
};

export const plasticTaxResolverUnits: ResolveFn<ExpenseResultDto[]> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getPackagingUnitIdFromRoute(route);
  const version = PathResolveService.getPackagingVersionIdFromRoute(route);
  if (id != null && version != null) {
    const evaluationGenerationDto: EvaluationGenerationDto = { id, version };
    return inject(AnalysisApiService).getPlasticTaxCalculationResult(evaluationGenerationDto, PackagingPart.Unit);
  }
  return of([] as ExpenseResultDto[]);
};

export const licenseFeeResolverUnits: ResolveFn<ExpenseResultDto[]> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getPackagingUnitIdFromRoute(route);
  const version = PathResolveService.getPackagingVersionIdFromRoute(route);
  if (id != null && version != null) {
    const evaluationGenerationDto: EvaluationGenerationDto = { id, version };
    return inject(AnalysisApiService).getLicenseFeeCalculationResult(evaluationGenerationDto, PackagingPart.Unit);
  }
  return of([] as ExpenseResultDto[]);
};
