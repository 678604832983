<app-packaging-navigation [itemId]="packagingPartId" [callerLevelId]="callerLevelId"
  [callerTypeId]="packagingUnitTypeId" [currentDirectoryId]="currentDirectoryId" [brandName]="brandName"
  [productName]="productName" [articleNumber]="articleNumber" [versionId]="packagingPartVersion"
  [isCurrentVersion]="isCurrentVersion" [hasRecyclabilityResult]="hasRecyclabilityResult"
  [hasLifeCycleResult]="hasLifeCycleResult" [creationTimestamp]="creationTimestamp">

  <div class="outer-container">
    <h1 class="mat-headline-3">{{ 'analysis.recyclabilityAnalysis.title' | translate }}</h1>

    <div id="versions-container" *ngIf="!isUserValidator">
      <mat-form-field class="form-field-versions">
        <mat-label>{{ 'home.packagingUnitHome.historyVersion' | translate }}</mat-label>
        <mat-select [(value)]="selectedAnalysisVersion" (selectionChange)="onHistoryVersionChange($event.value)">
          <mat-option *ngFor="let analysisVersion of analysisVersions" [value]="analysisVersion">
            {{ 'analysis.calculatedOn' | translate }}
            {{ getDateTimeString(analysisVersion[0].evaluationReferenceTimestamp) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <span id="analysis-id">
        {{ 'analysis.analysisId' | translate }}: <b>{{analysisId}}</b>
        <button id="copy-button" mat-icon-button color="primary" [cdkCopyToClipboard]="analysisId">
          <mat-icon>content_copy</mat-icon>
        </button>
      </span>
    </div>

    <mat-tab-group #countryTabs>
      <mat-tab label="{{ 'analysis.recyclabilityAnalysis.overview' | translate }}">
        <div class="inner-container">
          <div class="map-container">
            <app-map-recyclability-chart [chartDataSource]="dataSourceMap" [isDarkTheme]="isDarkTheme"
              (countryClicked)="openTab($event)">
            </app-map-recyclability-chart>
          </div>
        </div>
      </mat-tab>
      <mat-tab *ngFor="let element of dataSource; let tabIndex = index" [label]="element.evaluatedCountry">
        <ng-template mat-tab-label>
          <mat-icon *ngIf="!isCountryAuthorized(element.evaluatedCountryCode)">lock</mat-icon>
          <span>{{element.evaluatedCountry}}</span>
        </ng-template>
        <ng-template matTabContent>
          <div *ngIf="isUserValidator">
            <br>
            <alert *ngIf="element.validationState === validationStates.Unknown" type="info">
              {{ 'packagingUnit.messages.notValidated' | translate }}
            </alert>
            <alert *ngIf="element.validationState === validationStates.Positive" type="success">
              {{ 'packagingUnit.messages.validationPassed' | translate }}
            </alert>
            <alert *ngIf="element.validationState === validationStates.Negative" type="danger">
              {{ 'packagingUnit.messages.validationFailed' | translate }}
            </alert>
          </div>
          <div class="buybox" *ngIf="!isCountryAuthorized(element.evaluatedCountryCode)">
            <app-buying-from-shop  [buyType]="buyType.Country"
              [contentValue]="element.countryName">
            </app-buying-from-shop>
          </div>
          <div *ngIf="isCountryAuthorized(element.evaluatedCountryCode)">
            <div *ngIf="callerLevelId === packagingPartEnum.System && packagingSystem != null">
              <app-analysis-recyclability-packaging-system [selectedAnalysisVersion]="getSelectedAnalysisVersionForSystem(tabIndex)"
                [recyclingStreams]="recyclingStreams" [packagingSystem]="packagingSystem" [isDarkTheme]="isDarkTheme">
              </app-analysis-recyclability-packaging-system>
            </div>

            <div *ngIf="callerLevelId === packagingPartEnum.Unit && packagingUnit != null">
              <app-analysis-recyclability-packaging-unit [selectedAnalysisVersion]="getSelectedAnalysisVersionForUnit(tabIndex)"
                [recyclingStreams]="recyclingStreams" [packagingUnit]="packagingUnit" [isDarkTheme]="isDarkTheme">
              </app-analysis-recyclability-packaging-unit>
            </div>

            <div>
              <alert *ngFor="let disclaimer of element.disclaimers"type="info">
                <div [innerHTML]="getDisclaimerAsHtml(disclaimer)">
                </div>
              </alert>
            </div>

            <div class="button-container">
              <button *ngIf="isUserValidator" mat-raised-button color="primary" class="custom-validation-button"
                (click)="validateAnalysis(true, element)" type="button">
                <mat-icon>check</mat-icon>
                {{ 'common.text.valid' | translate }}
              </button>
              <button *ngIf="isUserValidator" mat-raised-button color="warn" class="custom-validation-button"
                (click)="validateAnalysis(false, element)" type="button">
                <mat-icon>clear</mat-icon>
                {{ 'common.text.invalid' | translate }}
              </button>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</app-packaging-navigation>
