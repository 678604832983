import { PackagingUnitInfoDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-info-dto';
import { DateHelperService } from './../../../services/date-helper.service';
import { MY_FORMATS } from './../../shared-components/menus/filter-menu/filter-menu.component';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { HistoryWrapperLifeCycle } from '../../../data-transfer/entities/evaluation-entities/history-wrapper-life-cycle';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, forkJoin, Observable, of } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { Moment } from 'moment';
import { PackagingUnitApiService } from 'src/app/data-transfer/services/packaging-unit-api-service';
import { AnalysisApiService } from 'src/app/data-transfer/services/analysis-api-service';
import { HistoryWrapperRecyclability } from 'src/app/data-transfer/entities/evaluation-entities/history-wrapper-recyclability';
import { PackagingUnitRecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-unit-recyclability-result-dto';
import { PackagingUnitLifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-unit-life-cycle-result-dto';
import { PackagingSystemText, PackagingUnitText } from 'src/app/model/path-building-blocks';
import { PackagingSystemInfoDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-info-dto';
import { PackagingSystemApiService } from 'src/app/data-transfer/services/packaging-system-api-service';
import { PackagingSystemRecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-system-recyclability-result-dto';
import { PackagingSystemLifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-system-life-cycle-result-dto';
import { PackagingSystemExpenseResultDto, PackagingUnitExpenseResultDto } from 'src/app/data-transfer/entities/evaluation-entities/expense-result-dto';

export enum ValidationState {
  Unknown,
  Positive,
  Negative
}

@Component({
  selector: 'app-validator-view',
  templateUrl: './validator-view.component.html',
  styleUrls: ['./validator-view.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class ValidatorViewComponent implements OnInit, OnDestroy {

  recDataSourceValidatedPos: MatTableDataSource<PackagingUnitInfoDto>;
  recDataSourceValidatedNeg: MatTableDataSource<PackagingUnitInfoDto>;
  recDataSourceNotValidated: MatTableDataSource<PackagingUnitInfoDto>;

  lcaDataSourceValidatedPos: MatTableDataSource<PackagingUnitInfoDto>;
  lcaDataSourceValidatedNeg: MatTableDataSource<PackagingUnitInfoDto>;
  lcaDataSourceNotValidated: MatTableDataSource<PackagingUnitInfoDto>;

  columnsNotRec: Array<string> = ['analysisId', 'packagingTypeName', 'brandName', 'productName', 'articleNumber', 'action'];
  columnsPosRec: Array<string> = ['analysisId', 'packagingTypeName', 'brandName', 'productName', 'articleNumber', 'action'];
  columnsNegRec: Array<string> = ['analysisId', 'packagingTypeName', 'brandName', 'productName', 'articleNumber', 'action'];

  columnsNotLca: Array<string> = ['analysisId', 'packagingTypeName', 'brandName', 'productName', 'articleNumber', 'action'];
  columnsPosLca: Array<string> = ['analysisId', 'packagingTypeName', 'brandName', 'productName', 'articleNumber', 'action'];
  columnsNegLca: Array<string> = ['analysisId', 'packagingTypeName', 'brandName', 'productName', 'articleNumber', 'action'];

  analysisWrappersRec: HistoryWrapperRecyclability[] = [];
  analysisWrappersLca: HistoryWrapperLifeCycle[] = [];

  rangeControl = new FormGroup({
    start: new FormControl<Moment | null>(null),
    end: new FormControl<Moment | null>(null),
  });

  isSystemView!: boolean;

  allPackagingParts: (PackagingSystemInfoDto | PackagingUnitInfoDto)[] = [];

  private routeUrlSubscription?: Subscription;
  private routeDataSubscription?: Subscription;
  private analysisDataSubscription?: Subscription;
  private filteredDataSubscription?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private analysisApiService: AnalysisApiService,
    private packagingSystemApiService: PackagingSystemApiService,
    private packagingUnitApiService: PackagingUnitApiService,
    private spinner: NgxSpinnerService,
    private dateHelperService: DateHelperService
  ) {
    this.recDataSourceValidatedPos = new MatTableDataSource<PackagingSystemInfoDto | PackagingUnitInfoDto>();
    this.recDataSourceValidatedNeg = new MatTableDataSource<PackagingSystemInfoDto | PackagingUnitInfoDto>();
    this.recDataSourceNotValidated = new MatTableDataSource<PackagingSystemInfoDto | PackagingUnitInfoDto>();

    this.lcaDataSourceValidatedPos = new MatTableDataSource<PackagingSystemInfoDto | PackagingUnitInfoDto>();
    this.lcaDataSourceValidatedNeg = new MatTableDataSource<PackagingSystemInfoDto | PackagingUnitInfoDto>();
    this.lcaDataSourceNotValidated = new MatTableDataSource<PackagingSystemInfoDto | PackagingUnitInfoDto>();
  }

  ngOnInit(): void {
    this.routeUrlSubscription = this.route.url.subscribe(segments => {
      this.isSystemView = segments.map(x => x.path).includes(PackagingSystemText);
      if (this.isSystemView) {
        this.columnsNotRec.splice(1, 1);
        this.columnsPosRec.splice(1, 1);
        this.columnsNegRec.splice(1, 1);
        this.columnsNotLca.splice(1, 1);
        this.columnsPosLca.splice(1, 1);
        this.columnsNegLca.splice(1, 1);
      }
    })
    this.routeDataSubscription = this.route.data.subscribe(data => {
      const startDate = this.dateHelperService.getDateInDaysMoment(moment(), -6);
      const endDate = this.dateHelperService.getDateInDaysMoment(moment(), 0);
      this.rangeControl.controls.start.setValue(startDate);
      this.rangeControl.controls.end.setValue(endDate);

      this.allPackagingParts = data.allPackagingParts ?? [];
      if (this.allPackagingParts.length > 0) {
        this.spinner.show();
        this.loadAnalysesData();
      }
    });
  }

  public applyFilter() {
    if (this.rangeControl.valid) {
      this.spinner.show();
      const range = this.rangeControl.value;
      let startDate: string | undefined;
      if (range.start) {
        startDate = this.dateHelperService.getDateInDaysUTCMoment(range.start, 0).toISOString();
      }
      let endDate: string | undefined;
      if (range.end) {
        endDate = this.dateHelperService.getDateInDaysUTCMoment(range.end, 1).toISOString();
      }
      const filteredPackagingPartObservable = this.isSystemView ?
        this.packagingSystemApiService.getFilteredPackagingSystems(startDate, endDate) :
        this.packagingUnitApiService.getFilteredPackagingUnits(startDate, endDate);
      this.filteredDataSubscription = filteredPackagingPartObservable.subscribe(packagingParts => {
        this.allPackagingParts = packagingParts ?? [];
        this.loadAnalysesData();
        this.rangeControl.markAsPristine();
      });
    }
  }

  public changeDateRangeByDays(days: number) {
    const range = this.rangeControl.value;
    if (range.start != null) {
      this.rangeControl.controls.start.setValue(this.dateHelperService.getDateInDaysMoment(range.start, days));
    }
    if (range.end != null) {
      this.rangeControl.controls.end.setValue(this.dateHelperService.getDateInDaysMoment(range.end, days));
    }
    this.rangeControl.markAsDirty();
  }

  private loadAnalysesData() {
    const allVersionsWithRecAnalysis = this.allPackagingParts.filter(x => x.hasRecyclabilityResult === true);
    const allVersionsWithLcaAnalysis = this.allPackagingParts.filter(x => x.hasLifeCycleResult === true);
    const allVersionsWithLicenseFeeResult = this.allPackagingParts.filter(x => x.hasLicenseFeesResult === true);
    const allVersionsWithPlasticTaxResults = this.allPackagingParts.filter(x => x.hasPlasticTaxesResult === true);

    const recObservables: Observable<(PackagingSystemRecyclabilityResultDto | PackagingUnitRecyclabilityResultDto)[][]>[] = [];
    const lcaObservables: Observable<(PackagingSystemLifeCycleResultDto | PackagingUnitLifeCycleResultDto)[][]>[] = [];
    const licenseFeeObservables: Observable<(PackagingSystemExpenseResultDto | PackagingUnitExpenseResultDto)[][]>[] = [];
    const plasticTaxObservables: Observable<(PackagingSystemExpenseResultDto | PackagingUnitExpenseResultDto)[][]>[] = [];

    allVersionsWithRecAnalysis.forEach(x => {
      if (x.id != null && x.version != null) {
        recObservables.push(this.isSystemView ?
          this.analysisApiService.getPackagingSystemRecyclabilityHistoryResults(x.id, x.version) :
          this.analysisApiService.getPackagingUnitRecyclabilityHistoryResults(x.id, x.version));
      }
    });
    allVersionsWithLcaAnalysis.forEach(x => {
      if (x.id != null && x.version != null) {
        lcaObservables.push(this.isSystemView ?
          this.analysisApiService.getPackagingSystemLifeCycleHistoryResults(x.id, x.version) :
          this.analysisApiService.getPackagingUnitLifeCycleHistoryResults(x.id, x.version));
      }
    });
    allVersionsWithLicenseFeeResult.forEach(x => {
      if (x.id != null && x.version != null) {
        licenseFeeObservables.push(this.isSystemView ?
          this.analysisApiService.getPackagingSystemLicenseFeeHistoryResults(x.id, x.version) :
          this.analysisApiService.getPackagingUnitLicenseFeeHistoryResults(x.id, x.version));
      }
    });
    allVersionsWithPlasticTaxResults.forEach(x => {
      if (x.id != null && x.version != null) {
        plasticTaxObservables.push(this.isSystemView ?
          this.analysisApiService.getPackagingSystemPlasticTaxHistoryResults(x.id, x.version) :
          this.analysisApiService.getPackagingUnitPlasticTaxHistoryResults(x.id, x.version));
      }
    });
    this.getTableData(recObservables, lcaObservables);
  }

  private getTableData(
    recObservables: Observable<(PackagingSystemRecyclabilityResultDto | PackagingUnitRecyclabilityResultDto)[][]>[],
    lcaObservables: Observable<(PackagingSystemLifeCycleResultDto | PackagingUnitLifeCycleResultDto)[][]>[]
  ) {
    let recEvaluations: Observable<(PackagingSystemRecyclabilityResultDto | PackagingUnitRecyclabilityResultDto)[][][]>;
    let lcaEvaluations: Observable<(PackagingSystemLifeCycleResultDto | PackagingUnitLifeCycleResultDto)[][][]>;
    if (recObservables.length > 0) {
      recEvaluations = forkJoin(recObservables);
    } else {
      recEvaluations = of([]);
    }
    if (lcaObservables.length > 0) {
      lcaEvaluations = forkJoin(lcaObservables);
    } else {
      lcaEvaluations = of([]);
    }
    this.analysisDataSubscription = forkJoin({
      recEvaluations,
      lcaEvaluations
    }).subscribe(results => {
      this.calcRecyclabilityTableData(results.recEvaluations);
      this.calcLifecycleTableData(results.lcaEvaluations);
      this.spinner.hide();
    });
  }

  private calcRecyclabilityTableData(
    packagingEvaluations: (PackagingSystemRecyclabilityResultDto | PackagingUnitRecyclabilityResultDto)[][][]
  ) {
    const validatedPosEvaluationIds: number[] = [];
    const validatedNegEvaluationIds: number[] = [];
    const notValidatedEvaluationIds: number[] = [];
    packagingEvaluations.forEach(packagingPart => {
      const analyzedPackagingPart = this.isSystemView ?
        (packagingPart[0][0] as PackagingSystemRecyclabilityResultDto).analyzedPackagingSystem :
        (packagingPart[0][0] as PackagingUnitRecyclabilityResultDto).analyzedPackagingUnit;
      const validatedPosCount = packagingPart[0].filter(country => country.validationState === ValidationState.Positive).length;
      const validatedNegCount = packagingPart[0].filter(country => country.validationState === ValidationState.Negative).length;
      const notValidatedCount = packagingPart[0].filter(country => country.validationState === ValidationState.Unknown).length;
      if (validatedNegCount > 0 && notValidatedCount === 0) {
        validatedNegEvaluationIds.push(analyzedPackagingPart.id ?? -1);
      } else if (validatedPosCount === packagingPart[0].length) {
        validatedPosEvaluationIds.push(analyzedPackagingPart.id ?? -1);
      } else {
        notValidatedEvaluationIds.push(analyzedPackagingPart.id ?? -1);
      }
      this.analysisWrappersRec.push({
        analysisId: packagingPart[0][0].id,
        id: analyzedPackagingPart.id ?? -1,
        version: analyzedPackagingPart.id ?? -1,
        recyclabilityDtos: packagingPart
      });
    });
    const validatedPos = this.allPackagingParts.filter(p => p.id != null && validatedPosEvaluationIds.includes(p.id));
    const validatedNeg = this.allPackagingParts.filter(p => p.id != null && validatedNegEvaluationIds.includes(p.id));
    const notValidated = this.allPackagingParts.filter(p => p.id != null && notValidatedEvaluationIds.includes(p.id));
    this.recDataSourceValidatedPos.data = validatedPos;
    this.recDataSourceValidatedNeg.data = validatedNeg;
    this.recDataSourceNotValidated.data = notValidated;
  }

  private calcLifecycleTableData(
    packagingLcaEvaluations: (PackagingSystemLifeCycleResultDto | PackagingUnitLifeCycleResultDto)[][][]
  ) {
    const validatedPosEvaluationIds: number[] = [];
    const validatedNegEvaluationIds: number[] = [];
    const notValidatedEvaluationIds: number[] = [];
    packagingLcaEvaluations.forEach(packagingPart => {
      const analyzedPackagingPart = this.isSystemView ?
        (packagingPart[0][0] as PackagingSystemLifeCycleResultDto).analyzedPackagingSystem :
        (packagingPart[0][0] as PackagingUnitLifeCycleResultDto).analyzedPackagingUnit;
      const validatedPosCount = packagingPart[0].filter(country => country.validationState === ValidationState.Positive).length;
      const validatedNegCount = packagingPart[0].filter(country => country.validationState === ValidationState.Negative).length;
      const notValidatedCount = packagingPart[0].filter(country => country.validationState === ValidationState.Unknown).length;
      if (validatedNegCount > 0 && notValidatedCount === 0) {
        validatedNegEvaluationIds.push(analyzedPackagingPart.id ?? -1);
      } else if (validatedPosCount === packagingPart[0].length) {
        validatedPosEvaluationIds.push(analyzedPackagingPart.id ?? -1);
      } else {
        notValidatedEvaluationIds.push(analyzedPackagingPart.id ?? -1);
      }
      this.analysisWrappersLca.push({
        analysisId: packagingPart[0][0].id,
        id: analyzedPackagingPart.id ?? -1,
        version: analyzedPackagingPart.id ?? -1,
        lifeCycleDtos: packagingPart
      });
    });
    const validatedPos = this.allPackagingParts.filter(p => p.id != null && validatedPosEvaluationIds.includes(p.id));
    const validatedNeg = this.allPackagingParts.filter(p => p.id != null && validatedNegEvaluationIds.includes(p.id));
    const notValidated = this.allPackagingParts.filter(p => p.id != null && notValidatedEvaluationIds.includes(p.id));
    this.lcaDataSourceValidatedPos.data = validatedPos;
    this.lcaDataSourceValidatedNeg.data = validatedNeg;
    this.lcaDataSourceNotValidated.data = notValidated;
  }

  ngOnDestroy(): void {
    this.routeUrlSubscription?.unsubscribe();
    this.routeDataSubscription?.unsubscribe();
    this.analysisDataSubscription?.unsubscribe();
    this.filteredDataSubscription?.unsubscribe();
  }
}
