import { SelectTagsDialogComponent } from './../tags/select-tags-dialog/select-tags-dialog.component';
import { TagDto } from './../../../../data-transfer/entities/tag-dto';
import { PackagingUnitInfoDto } from '../../../../data-transfer/entities/packaging-unit-entities/packaging-unit-info-dto';
import { CompositeMaterialsOverviewComponent } from './../composite-materials-overview/composite-materials-overview.component';
import { MultiMaterialCompositeDto } from 'src/app/data-transfer/entities/material-entities/multi-material-composite-dto';
import { ContextMenuService } from '../../services/context-menu-service';
import { DirectoryDto } from 'src/app/data-transfer/entities/directory-dto';
import { DirectoryApiService } from 'src/app/data-transfer/services/directory-api-service';
import { ActivatedRoute } from '@angular/router';
import { PackagingUnitTypeService } from 'src/app/navigation/services/packaging-unit-type-service';
import { PermissionTypeDto } from '../../../../data-transfer/entities/permission-type-dto';
import { PackagingComponentsOverviewComponent } from '../packaging-components-overview/packaging-components-overview.component';
import { PackagingComponentDto } from '../../../../data-transfer/entities/component-entities/packaging-component-dto';
import { Component, OnInit, ViewChild, OnDestroy, TemplateRef } from '@angular/core';
import { Subscription, Observable, forkJoin, of } from 'rxjs';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { PackagingUnitsOverviewComponent } from '../packaging-units-overview/packaging-units-overview.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { PackagingSystemInfoDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-info-dto';
import { PackagingSystemsOverviewComponent } from '../packaging-systems-overview/packaging-systems-overview.component';
import { DirectoryTreeComponent, RECYCLING_BIN_ID } from '../directory-tree/directory-tree.component';
import { OverviewTableComponent } from 'src/app/components/shared-components/parent-components/overview-table/overview-table.component';
import { ToolbarDirectoryComponent } from '../toolbar-directory/toolbar-directory.component';
import { MultiMaterialCompositeImportWrapper } from 'src/app/components/dialogs/selection-dialog-materials/selection-dialog-materials.component';

@Component({
  selector: 'app-directory-management',
  templateUrl: './directory-management.component.html',
  styleUrls: ['./directory-management.component.scss'],
})
export class DirectoryManagementComponent implements OnInit, OnDestroy {
  private packagingSystemsTable?: PackagingSystemsOverviewComponent;
  private packagingUnitsTable?: PackagingUnitsOverviewComponent;
  private componentsTable?: PackagingComponentsOverviewComponent;
  private materialsTable?: CompositeMaterialsOverviewComponent;

  @ViewChild(PackagingSystemsOverviewComponent) set packagingSystemsOverviewComponent(
    packagingSystemsTable: PackagingSystemsOverviewComponent
  ) {
    if (packagingSystemsTable) {
      this.packagingSystemsTable = packagingSystemsTable;
      this.setCurrentPackagingPartTable();
      this.updateIsEnterQunatitesAllowed();
    }
  }
  @ViewChild(PackagingUnitsOverviewComponent) set packagingUnitsOverviewComponent(
    packagingUnitsTable: PackagingUnitsOverviewComponent
  ) {
    if (packagingUnitsTable) {
      this.packagingUnitsTable = packagingUnitsTable;
      this.setCurrentPackagingPartTable();
      this.updateIsEnterQunatitesAllowed();
    }
  }
  @ViewChild(PackagingComponentsOverviewComponent) set packagingComponentsOverviewComponent(
    componentsTable: PackagingComponentsOverviewComponent
  ) {
    if (componentsTable) {
      this.componentsTable = componentsTable;
      this.setCurrentPackagingPartTable();
      this.updateIsEnterQunatitesAllowed();
    }
  }
  @ViewChild(CompositeMaterialsOverviewComponent) set compositeMaterialsOverviewComponent(
    materialsTable: CompositeMaterialsOverviewComponent
  ) {
    if (materialsTable) {
      this.materialsTable = materialsTable;
      this.updateIsEnterQunatitesAllowed();
    }
  }

  @ViewChild(ToolbarDirectoryComponent) private toolbar!: ToolbarDirectoryComponent;
  @ViewChild(DirectoryTreeComponent) directoryTree!: DirectoryTreeComponent;
  @ViewChild('contextMenu') contextMenu!: TemplateRef<any>;

  rootDtoFolder!: DirectoryDto;
  selectedDtoFolder?: DirectoryDto;
  selectedEntries: (PackagingSystemInfoDto | PackagingUnitInfoDto | PackagingComponentDto | MultiMaterialCompositeDto)[] = [];
  selectedPackagingPartTab = PackagingPart.System;
  private currentPackagingPartTable?: OverviewTableComponent;
  directoryPermissions: PermissionTypeDto = new PermissionTypeDto();
  recyclingBinId = RECYCLING_BIN_ID;
  isRecyclingBinSelected = false;
  isEnterQuantitiesAllowed = true;

  allTags: TagDto[] = [];
  treeDataSource = new MatTreeNestedDataSource<DirectoryDto>();
  packagingSystemsDataSource = new MatTableDataSource<PackagingSystemInfoDto>();
  packagingUnitsDataSource = new MatTableDataSource<PackagingUnitInfoDto>();
  componentsDataSource = new MatTableDataSource<PackagingComponentDto>();
  materialsDataSource = new MatTableDataSource<MultiMaterialCompositeImportWrapper>();

  displayedColumnsPackagingSystems = ['id', 'tracking', 'brandName', 'productName', 'articleNumber', 'tags',
    'action', 'select'];
  displayedColumnsPackagingUnits = ['id', 'tracking', 'packagingTypeName', 'brandName', 'productName', 'articleNumber', 'tags',
    'action', 'select'];
  displayedColumnsComponents = ['id', 'tracking', 'packagingComponentCategoryName', 'packagingComponentSubtypeName', 'articleName',
    'articleNumber', 'manufacturer', 'tags', 'action', 'select'];
  displayedColumnsMaterials = ['id', 'tracking', 'articleName', 'articleNumber', 'manufacturerName', 'tags', 'action', 'select'];

  private routeDataSubscription?: Subscription;
  private getPermissionsSubscription?: Subscription;
  private dirContentsSubscription?: Subscription;
  private dialogSubscription?: Subscription;
  private getFoldersSubscription?: Subscription;
  private tagsSubscription?: Subscription;

  constructor(
    private packagingUnitTypeService: PackagingUnitTypeService,
    private route: ActivatedRoute,
    private directoryApiService: DirectoryApiService,
    private contextMenuService: ContextMenuService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe(data => {
      this.rootDtoFolder = data.rootDtoDirectory;
      this.allTags = data.allTags;
    });

    this.route.params.subscribe(params => {
      if (params.folderName === 'upload-root') {
        this.onFolderSelected(this.rootDtoFolder);
      }
    });
  }

  onFolderSelected(dtoFolder: DirectoryDto) {
    this.selectedDtoFolder = dtoFolder;
    this.isRecyclingBinSelected = this.selectedDtoFolder?.id === this.recyclingBinId;
    this.setPermissionsForCurrentFolder(dtoFolder.id);
    this.setTablesDataSource(dtoFolder.id, false);
  }

  private setCurrentPackagingPartTable() {
    switch (this.selectedPackagingPartTab) {
      case PackagingPart.System:
        this.currentPackagingPartTable = this.packagingSystemsTable;
        break;
      case PackagingPart.Unit:
        this.currentPackagingPartTable = this.packagingUnitsTable;
        break;
      case PackagingPart.Component:
        this.currentPackagingPartTable = this.componentsTable;
        break;
      case PackagingPart.Material:
        this.currentPackagingPartTable = this.materialsTable;
        break;
      default:
        break;
    }
  }

  private updateIsEnterQunatitesAllowed() {
    switch (this.selectedPackagingPartTab) {
      case PackagingPart.System:
      case PackagingPart.Unit:
        this.isEnterQuantitiesAllowed = true
        break;
      case PackagingPart.Component:
      case PackagingPart.Material:
        this.isEnterQuantitiesAllowed = false
        break;
      default:
        this.isEnterQuantitiesAllowed = false
        break;
    }
  }

  private setPermissionsForCurrentFolder(directoryId: number) {
    this.getPermissionsSubscription = this.directoryApiService.determineCurrentUserPermissions(directoryId).subscribe(result => {
      this.directoryPermissions = {
        read: result.read,
        write: result.write,
        analyze: result.analyze,
        delete: result.delete,
      };
    });
  }

  onTabChanged() {
    this.resetPackagingPartSelection();
    this.setCurrentPackagingPartTable();
    this.updateIsEnterQunatitesAllowed();
  }

  packagingPartDataChanged() {
    if (!this.selectedDtoFolder) {
      return;
    }
    this.setTablesDataSource(this.selectedDtoFolder.id, true);
  }

  private setTablesDataSource(directoryId: number, loadCurrentPackagingPartOnly = false) {
    this.spinner.show();
    const loadPackagingSystems = !loadCurrentPackagingPartOnly || this.selectedPackagingPartTab === PackagingPart.System;
    const loadPackagingUnits = !loadCurrentPackagingPartOnly || this.selectedPackagingPartTab === PackagingPart.Unit;
    const loadComponents = !loadCurrentPackagingPartOnly || this.selectedPackagingPartTab === PackagingPart.Component;
    const loadMaterials = !loadCurrentPackagingPartOnly || this.selectedPackagingPartTab === PackagingPart.Material;
    let packagingSystemsObservable: Observable<PackagingSystemInfoDto[]>;
    let packagingUnitsObservable: Observable<PackagingUnitInfoDto[]>;
    let componentsObservable: Observable<PackagingComponentDto[]>;
    let materialsObservable: Observable<MultiMaterialCompositeDto[]>;
    if (this.isRecyclingBinSelected) {
      packagingSystemsObservable = loadPackagingSystems ? this.directoryApiService.getPackagingSystemsInRecyclingBin() : of([]);
      packagingUnitsObservable = loadPackagingUnits ? this.directoryApiService.getPackagingUnitsInRecyclingBin() : of([]);
      componentsObservable = loadComponents ? this.directoryApiService.getComponentsInRecyclingBin() : of([]);
      materialsObservable = loadMaterials ? this.directoryApiService.getMaterialsInRecyclingBin() : of([]);
    } else {
      packagingSystemsObservable = loadPackagingSystems ? this.directoryApiService.getPackagingSystemsInDirectory(directoryId) : of([]);
      packagingUnitsObservable = loadPackagingUnits ? this.directoryApiService.getPackagingUnitsInDirectory(directoryId) : of([]);
      componentsObservable = loadComponents ? this.directoryApiService.getComponentsInDirectory(directoryId) : of([]);
      materialsObservable = loadMaterials ? this.directoryApiService.getMaterialsInDirectory(directoryId) : of([]);
    }
    this.dirContentsSubscription = forkJoin([
      packagingSystemsObservable,
      packagingUnitsObservable,
      componentsObservable,
      materialsObservable,
    ]).subscribe(result => {
      this.packagingSystemsDataSource.data = loadPackagingSystems ? result[0] : this.packagingSystemsDataSource.data;
      this.packagingUnitsDataSource.data = loadPackagingUnits ? result[1] : this.packagingUnitsDataSource.data;
      this.componentsDataSource.data = loadComponents ? result[2] : this.componentsDataSource.data;
      this.materialsDataSource.data = (loadMaterials ? result[3] : this.materialsDataSource.data) as MultiMaterialCompositeImportWrapper[];
      this.resetPackagingPartSelection();
      this.spinner.hide();
    });
  }

  isGenericSelected(): boolean {
    if (this.selectedPackagingPartTab !== PackagingPart.Unit) {
      return false;
    }
    const genericPackagingUnit = (this.selectedEntries as PackagingUnitInfoDto[]).find(
      x => x.packagingTypeId != null && this.packagingUnitTypeService.isPackagingUnitTypeGeneric(x.packagingTypeId)
    );
    return genericPackagingUnit !== undefined;
  }

  moveFolder() {
    this.toolbar.moveFolder();
  }

  renameFolder() {
    this.toolbar.renameFolder();
  }

  removeFolder() {
    this.toolbar.removeFolder();
  }

  afterFolderRemoved() {
    this.getFoldersSubscription = this.directoryApiService.getDirectories().subscribe(rootDir => {
      this.rootDtoFolder = rootDir;
      this.updateView();
    });
  }

  afterTagsAssigned() {
    if (this.selectedPackagingPartTab === PackagingPart.Unit) {
      this.packagingUnitsTable?.cd.detectChanges();
    }
    this.resetPackagingPartSelection();
  }

  updateView() {
    this.directoryTree.updateDirectoryTreeData(this.rootDtoFolder);
    this.contextMenuService.closeContextMenu();
  }

  emptyRecyclingBin() {
    this.packagingSystemsTable?.emptyRecyclingBin();
    this.packagingUnitsTable?.emptyRecyclingBin();
    this.componentsTable?.emptyRecyclingBin();
    this.materialsTable?.emptyRecyclingBin();
  }

  restoreAllItemsFromRecyclingBin() {
    this.packagingSystemsTable?.restoreAllPackagingSystems();
    this.packagingUnitsTable?.restoreAllPackagingUnits();
    this.componentsTable?.restoreAllPackagingComponents();
    this.materialsTable?.restoreAllMaterials();
  }

  editTags() {
    this.assignTags(true);
  }

  addTags() {
    this.assignTags(false);
  }

  private assignTags(isEditing: boolean) {
    if (!this.currentPackagingPartTable) {
      return;
    }

    const itemToTag = isEditing ? this.selectedEntries[0] : undefined;
    const itemsToTag = isEditing ? undefined : this.selectedEntries;
    const tagsFunction = this.currentPackagingPartTable.getTagsSettingFunction(isEditing);

    const dialogConfig = getDialogConfig({ tags: this.allTags, item: itemToTag }, '800px');
    const dialogRef = this.dialog.open(SelectTagsDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(selectedTagIds => {
      if (!selectedTagIds) {
        return;
      }
      if (isEditing) {
        if (itemToTag === undefined || itemToTag.id == null) {
          return;
        }
        itemToTag.associatedTagIdentifiers = selectedTagIds;
        this.tagsSubscription = tagsFunction(itemToTag.id, selectedTagIds).subscribe();
      } else {
        if (itemsToTag === undefined) {
          return;
        }
        for (const itemToTag of itemsToTag) {
          if (itemToTag.id != null) {
            this.tagsSubscription = tagsFunction(itemToTag.id, selectedTagIds).subscribe(tagIds => {
              itemToTag.associatedTagIdentifiers = tagIds;
            });
          }
        }
        if (this.selectedPackagingPartTab === PackagingPart.Unit) {
          this.packagingUnitsTable?.cd.detectChanges();
        }
      }
    });
    this.resetPackagingPartSelection();
  }

  movePackagingPart() {
    if (!this.selectedDtoFolder || !this.currentPackagingPartTable) {
      return;
    }
    this.currentPackagingPartTable.movePackagingPart(this.rootDtoFolder, [this.selectedDtoFolder]);
  }

  copyPackagingPart() {
    if (!this.currentPackagingPartTable) {
      return;
    }
    this.currentPackagingPartTable.copyPackagingPart(this.rootDtoFolder, []);
  }

  deletePackagingPart() {
    if (!this.currentPackagingPartTable) {
      return;
    }
    this.currentPackagingPartTable.deletePackagingPart();
  }

  restorePackagingPart() {
    if (!this.currentPackagingPartTable) {
      return;
    }
    this.currentPackagingPartTable.restorePackagingPart();
  }

  exportPackagingPart() {
    if (!this.currentPackagingPartTable) {
      return;
    }
    this.currentPackagingPartTable.exportPackagingPart();
  }

  comparePackagingPart() {
    if (!this.currentPackagingPartTable) {
      return;
    }
    this.currentPackagingPartTable.comparePackagingPart();
  }

  editPackagingPartQuantity() {
    if (!this.currentPackagingPartTable) {
      return;
    }
    this.currentPackagingPartTable.editPackagingPartQuantity();
  }

  private resetPackagingPartSelection() {
    if (!this.currentPackagingPartTable) {
      return;
    }
    this.currentPackagingPartTable.resetSelection();
  }

  packagingEntriesSelected(
    selectedEntries: (PackagingSystemInfoDto | PackagingUnitInfoDto | PackagingComponentDto | MultiMaterialCompositeDto)[]
  ): void {
    this.selectedEntries = selectedEntries;
  }

  downloadPackagingUnitsJson() {
    if (this.selectedPackagingPartTab !== PackagingPart.Unit) {
      return;
    }
    this.packagingUnitsTable?.downloadPackagingUnitsJson();
  }

  ngOnDestroy(): void {
    this.routeDataSubscription?.unsubscribe();
    this.getPermissionsSubscription?.unsubscribe();
    this.dirContentsSubscription?.unsubscribe();
    this.dialogSubscription?.unsubscribe();
    this.getFoldersSubscription?.unsubscribe();
    this.tagsSubscription?.unsubscribe();
  }
}
