import { InlayDto } from './inlay-dto';
import { PackagingComponentEntryDto } from './packaging-component-entry-dto';

export class InlayEntryDto extends PackagingComponentEntryDto {
    isAdhesiveUsed: boolean | null = null;
    removabilityConditionId = -1;
    isPhysicallyAttached: boolean | null = null;
    removedBeforeUsage = false;
    removedByConsumer = false;
    underlyingComponent!: InlayDto;
    separationThroughCollectionAndSorting: boolean | null = null;
}
