import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PackagingUnitExpenseResultDto } from 'src/app/data-transfer/entities/evaluation-entities/expense-result-dto';
import { PackagingUnitDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-dto';
import { LICENSE_FEE_COUNTRIES } from 'src/app/model/expense-countries';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { ExpenseCalculationParentComponent } from '../expense-calculation-parent/expense-calculation-parent.component';

@Component({
  selector: 'app-license-fee-packaging-unit',
  templateUrl: './license-fee-packaging-unit.component.html',
  styleUrls: ['./license-fee-packaging-unit.component.scss']
})
export class LicenseFeePackagingUnitComponent extends ExpenseCalculationParentComponent implements OnInit {

  licenseFeeResult!: PackagingUnitExpenseResultDto[];
  packagingUnit!: PackagingUnitDto;

  constructor(private route: ActivatedRoute) { super(); }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe(data => {
      const packagingUnit = data.licenseFeeResult[0][0].analyzedPackagingUnit;

      this.licenseFeeResult = data.licenseFeeResult[0];
      this.packagingUnit = packagingUnit;
      this.packagingPartWeight = super.getPackagingUnitWeight(this.packagingUnit);
      this.countriesAvailability = LICENSE_FEE_COUNTRIES;
      this.packagingPartType = PackagingPart.Unit;
    });
  }
}
